import React, {useState, useEffect} from 'react'
import SweetAlert from 'react-bootstrap-sweetalert'
import { FaGasPump  } from 'react-icons/fa'
import {FcReading, FcAddressBook} from 'react-icons/fc'
import Cargando from '../../componentes/cargando/cargar'
import {GetCuadroControlCombustible, EnviarExcelConsumoCombutible} from  '../../componentes/api/consumirapi'
import ArbolDatos from '../../componentes/basicos/vistaArbol'
import {ConsumoCombustible} from './reporteconsumocombustible'

export default function GestionCombustible(props){
    
    const [error, setError] = useState(null)    
    const [cargando, setCargando] = useState(true)
    const [titulo, setTitulo] = useState()    
    const [showConsumoCombustible, setshowConsumoCombustible] = useState(false)    
    const [idRegistro, setidRegistro] = useState(null)
    const [estadoBotones, setEstadoBotones] = useState({CmdConfirmar:{visible: false, activo: false},                                                        
                                                        CmdEjecutar:{visible: false, activo: false},                                                        
                                                        CmdExportar:{visible: false, activo: false}})
    const [urlMicroservicio,seturlMicroservicio]=useState(props.configuracion.microServicio)                                                
    const [arbol, setArbol] = useState([])
    const [actualizarDatos, setactualizarDatos] = useState(false)
    const [crudEjecutadoBien, setCrudEjecutadoBien] = useState({accion:'', 
                                                                titulo:'', 
                                                                mensaje: '',
                                                                btnCancelar: false, 
                                                                mostrar: false,
                                                                tipoMensaje: '',
                                                                rowId: null    
                                                            })                                                            
    const onCerrarActividades = () =>{
        setCargando(true)
        setTimeout(() =>{
            props.setPantalla(antes=>{
                               return {...antes,
                                       consumocombustible: {activa: false, configuracion: null, nuevallamada: false}, 
                                       principal:{activa: true}}
                            })
            setCargando(false)
        },200)
    }  

    const onExportar = async () =>{
      
        let respuestaServicio = await EnviarExcelConsumoCombutible({urlMicroservicio}, props.onCambiarOffline)            
            .then(respObj => {
                const mensajeRespuesta=respObj.mensaje;
                console.log(mensajeRespuesta);   
                return mensajeRespuesta;        
            })
            .catch(error => {
                console.log({ error })
                window.alert(error.message);
            }); 
       if (respuestaServicio)
            window.alert(respuestaServicio);
       console.log(respuestaServicio);
    }


    const onAbrirEjecutar =()=> {
        
        setshowConsumoCombustible(true);
    }

    const onConfirmGuardar = (e) => {
        //setCallUseEfect(callUseEfect?false:true)
        setCrudEjecutadoBien({accion:'', titulo:'', mensaje: '',btnCancelar: false, mostrar: false, tipoMensaje: '', rowId: null})
    }

    const onConfirmErrorGuardar = (e) =>{       
        setCrudEjecutadoBien({accion:'', titulo:'', mensaje: '',btnCancelar: false, mostrar: false, tipoMensaje: '', rowId: null})
    }

    const onCancelar = () => {
        setCrudEjecutadoBien({accion:'', titulo:'', mensaje: '',btnCancelar: false, mostrar: false, tipoMensaje: '', rowId: null})
    }

    useEffect(() => {
        
        setTitulo(null)
        const obtieneDatos = async ()=>{
            setCargando(true)
            let apiConsumoCombustible = await GetCuadroControlCombustible(props, ()=>console.log)
                                .then((datos)=>{ return datos })
            if(apiConsumoCombustible!==false){
                window.localStorage.setItem('consumoscombustible', JSON.stringify(apiConsumoCombustible))
            }else{
                apiConsumoCombustible = JSON.parse(window.localStorage.getItem('consumoscombustible'))
            }
            if(apiConsumoCombustible!==null && apiConsumoCombustible.listadoObjetosEstado!=null){
                setTitulo(apiConsumoCombustible.tituloPantalla)
                setEstadoBotones(antes=>{
                    let old = []
                    apiConsumoCombustible.listadoObjetosEstado.map(estado => {
                        old[estado.id] = {visible: estado.visible, activo: estado.activo}
                    })
                    return old
                })
                setArbol(apiConsumoCombustible.datatable)
                
            }else{
                setError(`No existen reportes en el dispositivo. Por favor intente de 
                          nuevo cuando cuente con acceso a internet`)
            }
            setCargando(false)
        }
        obtieneDatos()
    },[actualizarDatos])
    
    useEffect(() => {
        const tareaSeleccionada = arbol.filter(e => e.id === idRegistro);
        if (tareaSeleccionada.length > 0) {
            const {id} = tareaSeleccionada[0];            
            window.localStorage.setItem('recSel', JSON.stringify(tareaSeleccionada));
            setidRegistro(id)
            setshowConsumoCombustible(false)          
        }
        return () => {
             localStorage.removeItem('recSel')
             setshowConsumoCombustible(false)
        }
    }, [idRegistro])

    if(cargando)
        return <Cargando />

    if(error!==null)
        return <div>
                    <div className="panel panel-default">
                        <div className="panel-heading text-center border"><b>Capsule Combustible - {titulo}</b></div>
                        <div className="panel-body border">
                            <div className="alert alert-danger text-center m-2 "> {error}{titulo}</div>
                        </div>
                    </div>
                </div>

    return (
        <div>
            <div className="panel panel-default">
                <div className="panel-heading text-center border">
                    <b>{titulo}</b>
                    <button className="btn btn-danger btn-sm m-0" 
                            style={{float:'right'}} 
                            onClick={()=>onCerrarActividades()}>Cerrar</button>                    
                </div>
                <div className="panel-body border">                          
                    {estadoBotones.CmdEjecutar.visible && 
                        <button className="btn btn-light botonesSuperior" 
                                disabled={JSON.parse(window.localStorage.getItem('offline')).modeOffline||
                                            !estadoBotones.CmdEjecutar.activo || idRegistro !== null } 
                                onClick={() => onAbrirEjecutar()}>
                                    <span><FaGasPump className="tamanoImg" />
                                        <p className="tamanoLetraImg">Registrar</p>
                                    </span>
                        </button>}

                    {estadoBotones.CmdConfirmar.visible && 
                        <button className="btn btn-light botonesSuperior" 
                                disabled={JSON.parse(window.localStorage.getItem('offline')).modeOffline||
                                            !estadoBotones.CmdConfirmar.activo  || idRegistro === null} 
                                onClick={() => onAbrirEjecutar()}>
                                    <span><FcReading className="tamanoImg" />
                                        <p className="tamanoLetraImg">Reporte</p>
                                    </span>
                        </button>}

                    {estadoBotones.CmdExportar.visible && 
                        <button className="btn btn-light botonesSuperior" 
                                disabled={JSON.parse(window.localStorage.getItem('offline')).modeOffline||
                                            !estadoBotones.CmdExportar.activo}  
                                onClick={() => onExportar()}>
                                    <span><FcAddressBook className="tamanoImg" />
                                        <p className="tamanoLetraImg">Exportar</p>
                                    </span>
                        </button>}
                    
                
                </div>
                
                {!cargando && <ArbolDatos arbol={arbol} setidRegistro={setidRegistro}/>}

                {!cargando && showConsumoCombustible && <ConsumoCombustible showConsumoCombustible={showConsumoCombustible} idRegistro={idRegistro}
                    arbol={arbol} setshowConsumoCombustible={setshowConsumoCombustible} urlMicroservicio={urlMicroservicio}  
                    onCambiarOffline={props.onCambiarOffline} setCrudEjecutadoBien={setCrudEjecutadoBien}  
                    setCargando={setCargando} 
                    setactualizarDatos={setactualizarDatos} actualizarDatos={actualizarDatos}>
                </ConsumoCombustible>}
                
            </div>
            {!cargando && <div>
                <SweetAlert
                    show={crudEjecutadoBien.mostrar && crudEjecutadoBien.tipoMensaje==='success'}
                    success
                    title={crudEjecutadoBien.titulo}
                    onConfirm={()=>onConfirmGuardar()}
                    showCancel={crudEjecutadoBien.btnCancelar}
                    confirmBtnText={"Aceptar"}
                    closeOnClickOutside={false}
                    showCloseButton={true}>
                        <p>{crudEjecutadoBien.mensaje}</p>
                </SweetAlert>

                <SweetAlert
                    show={crudEjecutadoBien.mostrar && crudEjecutadoBien.tipoMensaje==='warning'}
                    warning
                    title={crudEjecutadoBien.titulo}
                    onConfirm={()=>onConfirmErrorGuardar()}
                    onCancel={()=>onCancelar()}
                    showCancel={crudEjecutadoBien.btnCancelar}
                    confirmBtnText={"Aceptar"}
                    closeOnClickOutside={false}
                    showCloseButton={true}>
                        <p>{crudEjecutadoBien.mensaje}</p>
                </SweetAlert>
            </div>}
        </div>
    )
}

