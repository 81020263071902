import { urlBase, TipoClienteAccede } from './datosapi'

const configFecha = {weekday: "long", year: "numeric", month: "long", day: "numeric", hour: "numeric", hour12:"false"};

//Consumo de Api para la principal

export const CerrarSesion = async() => {
    const url = urlBase + '/api/Home'
    const usuario = JSON.parse(window.localStorage.getItem('logearUsuario'))
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${usuario.token}`,
        }
    }
    const remover = () => {
        localStorage.removeItem('offline')
        localStorage.removeItem('logearUsuario')
        localStorage.removeItem('tablaMaestra')
        localStorage.removeItem('consumoscombustible')
        localStorage.removeItem('idReporteSync')
        localStorage.removeItem('maestrasUsuario')
        localStorage.removeItem('recSel')
        localStorage.removeItem('tarSel')
    }

    await fetch(url, requestOptions)
        .then(respuesta => {
            if (respuesta.ok) {
                remover()
                window.location.reload()
            }
        }).catch(error => {
            remover()
            window.location.reload()
        })
}

export const ObtenerPerfil = async(onCambiarOffline) => {
    const url = urlBase + '/api/Home'
    const usuario = JSON.parse(window.localStorage.getItem('logearUsuario'))
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${usuario.token}`,
        }
    }

    const retorno = await fetch(url, requestOptions)
        .catch(error => {
            onCambiarOffline(true, error)
        })

    if (retorno !== undefined && retorno.status === 200) {
        onCambiarOffline(false, null)
        let datos = retorno.json()
        .then(respObj => {
            window.localStorage.setItem('maestrasUsuario', JSON.stringify({ MaestrasUsuario: respObj.maestrasUsuario }))
            return respObj;
        })
        window.localStorage.setItem('offline', JSON.stringify({ modeOffline: false }))
        window.localStorage.setItem('logearUsuario', JSON.stringify({...usuario, perfilUsuario: await datos }))
        return true
    } else if (retorno !== undefined && retorno.status !== 200) {
        //onCambiarOffline(true, retorno.status)
    }

}

export const Notificaciones = async(props) => {
    const url = urlBase + '/api/Notificaciones'
    const usuario = JSON.parse(window.localStorage.getItem('logearUsuario'))
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${usuario.token}`
        }
    }

    const retorno = await fetch(url, requestOptions)
        .catch(error => {
            props.onCambiarOffline(true, error)
        })

    if (retorno !== undefined && retorno.status === 200) {
        props.onCambiarOffline(false, null)
        window.localStorage.setItem('offline', JSON.stringify({ modeOffline: false }))
        let datos = retorno.json()
        return datos
    } else if (retorno !== undefined && retorno.status !== 200) {
        //onCambiarOffline(true, retorno.status)
    }

    return false
}

export const confirmarCerrarNotificacion = async(nota, props) => {
    const usuario = JSON.parse(window.localStorage.getItem('logearUsuario'))
    const url = urlBase + '/api/Notificaciones'
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${usuario.token}`
        },
        body: JSON.stringify(nota)
    };

    const retorno = await fetch(url, requestOptions)
        .catch(error => {
            props.onCambiarOffline(true, error)
        })

    if (retorno !== undefined && retorno.status === 200) {
        window.localStorage.setItem('offline', JSON.stringify({ modeOffline: false }))
        props.onCambiarOffline(false, null)
        let datos = retorno.json()
        return (datos === true) ? true : false
    } else if (retorno !== undefined && retorno.status !== 200) {
        //onCambiarOffline(true, retorno.status)
    }

    return false
}

//Consumo de Api para las Maestras

export const GetDatosMaestra = async(props, onCambiarOffline) => {
    const url = props.configuracion.microServicio
    const usuario = JSON.parse(window.localStorage.getItem('logearUsuario'))
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${usuario.token}`,
            'Georef': usuario.ubicacion
        }
    }

    const retorno = await fetch(url, requestOptions)
        .catch(error => { onCambiarOffline(true, error) })

    if (retorno !== undefined && retorno.status === 200) {
        window.localStorage.setItem('offline', JSON.stringify({ modeOffline: false }))
        onCambiarOffline(false, null)
        let datos = retorno.json()
        return datos
    } else if (retorno !== undefined && retorno.status !== 200) {
        //onCambiarOffline(true, retorno.status)
    }
    return false
}

export const GuardarMaestra = async(datos, onCambiarOffline, setCrudEjecutadoBien, microServicio, datosImagen) => {
    const url = microServicio
    const usuario = JSON.parse(window.localStorage.getItem('logearUsuario'))
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${usuario.token}`,
            'Georef': usuario.ubicacion
        },
        body: JSON.stringify(datos)
    }


    const retorno = await fetch(url, requestOptions)
        .catch(error => { onCambiarOffline(true, error) })

    if (retorno !== undefined && retorno.status === 200) {
        const elId = await retorno.text().then(valor => { return valor })
        if (datosImagen !== null) {
            datosImagen.id = elId
            await guardarImagen(datosImagen)
        }
        return true
    } else if (retorno !== undefined && retorno.status !== 200) {
        let datos = retorno.json()
        datos.then(d => {
            let textoError = ''
            if (d.errors !== undefined) {
                let objetoError = Object.values(d.errors)
                for (let i = 0; i < objetoError.length; i++) {
                    textoError = `${textoError} ${i+1}- ${objetoError[i]}   `
                }
            } else {
                textoError = d.mensaje
            }
            setCrudEjecutadoBien({
                accion: '',
                titulo: 'Error',
                mensaje: textoError,
                btnCancelar: false,
                mostrar: true,
                tipoMensaje: 'warning',
                rowId: null
            })
        })
    }
    return false
}


export const EliminarMaestra = async(datos, onCambiarOffline, setCrudEjecutadoBien, microServicio) => {
    const url = microServicio
    const usuario = JSON.parse(window.localStorage.getItem('logearUsuario'))
    const requestOptions = {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${usuario.token}`,
            'Georef': usuario.ubicacion
        },
        body: JSON.stringify(datos)
    }

    const retorno = await fetch(url, requestOptions)
        .catch(error => { onCambiarOffline(true, error) })

    if (retorno !== undefined && retorno.status === 200) {
        return true
    } else if (retorno !== undefined && retorno.status !== 200) {
        let datos = retorno.json()
        datos.then(d => {
            let textoError = ''
            if (d.errors !== undefined) {
                let objetoError = Object.values(d.errors)
                for (let i = 0; i < objetoError.length; i++) {
                    textoError = `${textoError} ${i+1}- ${objetoError[i]}   `
                }
            } else {
                textoError = d.mensaje
            }
            setCrudEjecutadoBien({
                accion: '',
                titulo: 'Error',
                mensaje: textoError,
                btnCancelar: false,
                mostrar: true,
                tipoMensaje: 'warning',
                rowId: null
            })
        })
    }
    return false
}

//Consumo de Api para control combustible

export const GetCuadroControlCombustible = async(props, onCambiarOffline) => {
    const url = props.configuracion.microServicio
    const usuario = JSON.parse(window.localStorage.getItem('logearUsuario'))
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${usuario.token}`,
            'Georef': usuario.ubicacion
        }
    }

    const retorno = await fetch(url, requestOptions)
        .catch(error => { onCambiarOffline(true, error) })

    if (retorno !== undefined && retorno.status === 200) {
        window.localStorage.setItem('offline', JSON.stringify({ modeOffline: false }))
        onCambiarOffline(false, null)
        let datos = await retorno.json()
        return datos
    } else if (retorno !== undefined && retorno.status !== 200) {
        //onCambiarOffline(true, retorno.status)
    }
    return false
}

export const GetReporteConsumoCombustible = async(props, onCambiarOffline) => {    
    const url = props.microServicio + '/id=' + (props.idreporte===undefined || props.idreporte===null ? '00000000-0000-0000-0000-000000000000':props.idreporte)
    const usuario = JSON.parse(window.localStorage.getItem('logearUsuario'))
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${usuario.token}`,
            'Georef': usuario.ubicacion
        }
    }

    const retorno = await fetch(url, requestOptions)
        .catch(error => { onCambiarOffline(true, error) })

    if (retorno !== undefined && retorno.status === 200) {
        window.localStorage.setItem('offline', JSON.stringify({ modeOffline: false }))
        onCambiarOffline(false, null)
        let datos = retorno.json()
        return datos
    } else if (retorno !== undefined && retorno.status !== 200) {
        //onCambiarOffline(true, retorno.status)
    }
    return false
}

export const GuardarConsumoCombustible = async(datos, onCambiarOffline, setCrudEjecutadoBien, microServicio) => {
    const url = microServicio
    const usuario = JSON.parse(window.localStorage.getItem('logearUsuario'))

    const {fotoFactura,fotoTacometro} = datos;
    datos.fotoFactura='==><'
    datos.fotoTacometro='==><'
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${usuario.token}`,
            'Georef': usuario.ubicacion
        },
        body: JSON.stringify(datos)
    }


    const retorno = await fetch(url, requestOptions)
        .catch(error => { onCambiarOffline(true, error) })

    if (retorno !== undefined && retorno.status === 200) {
        const elId = await retorno.text().then(valor => { return valor })
        .then(async(valor)=>{
            if (fotoTacometro !== null && fotoTacometro!=='') {
                const FotoTacometro={
                    pantalla:'ConsumoCombustible', 
                    fileCab:'Tacómetro',
                    id : valor,
                    data64File:fotoTacometro,
                    fileName: 'Tacómetro.' + datos.filename.split(';')[0]
                }                              
                 await guardarImagen(FotoTacometro,true).catch(resultado=>{return false})               
            }
            if (fotoFactura !== null && fotoFactura!=='') {
                const FotoFactura={
                    pantalla:'ConsumoCombustible', 
                    fileCab:'Factura',
                    id : valor,
                    data64File:fotoFactura,
                    fileName: 'Factura.' + datos.filename.split(';')[1]
                }             
               await guardarImagen(FotoFactura,true).catch(resultado=>{return false})  
            } 
            return valor
        });
        return elId
    } else if (retorno !== undefined && retorno.status !== 200) {
        let datos = retorno.json()
        datos.then(d => {
            let textoError = ''
            if (d.errors !== undefined) {
                let objetoError = Object.values(d.errors)
                for (let i = 0; i < objetoError.length; i++) {
                    textoError = `${textoError} ${i+1}- ${objetoError[i]}   `
                }
            } else {
                textoError = d.mensaje
            }
            setCrudEjecutadoBien({
                accion: '',
                titulo: 'Error',
                mensaje: textoError,
                btnCancelar: false,
                mostrar: true,
                tipoMensaje: 'warning',
                rowId: null
            })
        })
    }
    return false
}

export const ConceptoAprobacionConsumoCombustible = async(datos, onCambiarOffline, setCrudEjecutadoBien, microServicio) => {
    const url = microServicio
    const usuario = JSON.parse(window.localStorage.getItem('logearUsuario'))
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${usuario.token}`,
            'Georef': usuario.ubicacion
        },
        body: JSON.stringify(datos)
    }


    const retorno = await fetch(url, requestOptions)
        .catch(error => { onCambiarOffline(true, error) })

    if (retorno !== undefined && retorno.status === 200) {
        const elId = await retorno.text().then(valor => { return valor })        
        return elId
    } else if (retorno !== undefined && retorno.status !== 200) {
        let datos = retorno.json()
        datos.then(d => {
            let textoError = ''
            if (d.errors !== undefined) {
                let objetoError = Object.values(d.errors)
                for (let i = 0; i < objetoError.length; i++) {
                    textoError = `${textoError} ${i+1}- ${objetoError[i]}   `
                }
            } else {
                textoError = d.mensaje
            }
            setCrudEjecutadoBien({
                accion: '',
                titulo: 'Error',
                mensaje: textoError,
                btnCancelar: false,
                mostrar: true,
                tipoMensaje: 'warning',
                rowId: null
            })
        })
    }
    return false
}

export const EnviarExcelConsumoCombutible = async(props, onCambiarOffline) => {        
    const url = urlBase + '/api/ExportaMasivos/ExportarCombustible'
    const usuario = JSON.parse(window.localStorage.getItem('logearUsuario'))
    const datos={'TipoClienteAccede':TipoClienteAccede}
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${usuario.token}`,
            'Georef': usuario.ubicacion
        },
        body: JSON.stringify(datos)
    }

    const retorno = await fetch(url, requestOptions)
        .catch(error => { onCambiarOffline(true, error) })

    if (retorno !== undefined && retorno.status === 200) {
        window.localStorage.setItem('offline', JSON.stringify({ modeOffline: false }))
        onCambiarOffline(false, null)
        const datos = await retorno.json()
        return datos
    } else if (retorno !== undefined && retorno.status !== 200) {
        //onCambiarOffline(true, retorno.status)
    }
    return false
}
     

const guardarImagen = async(imagen64, esConsumoCombustible=false) => {
    const url = urlBase + '/api/Archivos'
    const usuario = JSON.parse(window.localStorage.getItem('logearUsuario'))

    const datos = {
        partitionKey: imagen64.pantalla,
        id: imagen64.id,
        FileName: imagen64.fileName,
        fileCab: imagen64.fileCab,
        data64File: imagen64.data64File
    }

    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${usuario.token}`,
            'Georef': usuario.ubicacion
        },
        body: JSON.stringify(datos)
    }

    await fetch(url, requestOptions).catch(error => {})
}
