import React from 'react'
import {useState, useEffect} from 'react'
import {Table} from 'react-bootstrap'
import { FaAngleDown, FaSearch} from 'react-icons/fa'
import {GetCuadroControlCombustible} from '../../componentes/api/consumirapi'

export default function ArbolDatos(props){
    
    const [listadoArbol, setListadoArbol] = useState([])
    const [originalArbol, setOriginalArbol] = useState([])
    const [valorFiltro, setValorFiltro] = useState(null)
    

    let objetoArbol = [];
    let objetoSeleccionado = []
    props.arbol.forEach((registro)=>{
        //Verifica si existe el id en el objeto objetoArbol
        const resultado = objetoArbol.find( rama => rama.padre===registro.padre);
        //No existe agrega (padre)
        if(!resultado){
            objetoArbol.push({padre:registro.padre,
                              hijo:[{id:registro.id,                                      
                                     descripcion:registro.descripcion}]})
        }else{//Existe lo actualiza (Hijo)
            const index = objetoArbol.findIndex(rama => rama.padre === registro.padre )
            objetoArbol[index].hijo.push({id:registro.id,                                           
                                          descripcion:registro.descripcion})
        }
        objetoSeleccionado.push({estado:false, idSelect: registro.id})
        
        
    })
    let [registroChecked/* , setRegistroChecked */] = useState(objetoSeleccionado)
    const abrirArbol = (identificador) =>{
        let hijorRama = document.getElementById(identificador)
        hijorRama.hidden=hijorRama.hidden?false:true
    }

    const onFiltrarArbol = (e) =>{
        setValorFiltro(e.target.value)
    }

    const onChangeCheck = (id) => {
        let campoSelect = document.getElementById(id)        
        if(campoSelect.checked){
            registroChecked.map((obj,idRow)=>{
                registroChecked[idRow] = {...registroChecked[idRow], estado:false}

                let item = document.getElementById(registroChecked[idRow].idSelect)
                if(item!==null) {
                    item.checked = false
                    if(registroChecked[idRow].idSelect===id){
                        registroChecked[idRow] = {...registroChecked[idRow], estado:true}
                        item.checked = true
                        props.setidRegistro(old=>{return registroChecked[idRow].idSelect})
                    }
                }
            })
        }else{
            campoSelect.checked = false
            props.setidRegistro(old=>{return null})
        }
    }

    useEffect(() => {
        let datosArbol = []
        let arbol = []
        if(valorFiltro!==null){
            arbol = [...originalArbol]                       
            if(valorFiltro!==null)
                arbol = arbol.filter(objArbol => objArbol.padre.includes(valorFiltro))
            
        }else{
            arbol=objetoArbol
            setOriginalArbol(objetoArbol)
        }

        datosArbol = arbol.map((rama, i)=>{
            return  <div id={rama +i}>
                        <a onClick={()=>abrirArbol(rama.padre)}>
                            <FaAngleDown />
                            <b>{rama.padre}</b>
                        </a>
                        <Table id={rama.padre} hidden>{rama.hijo.map((datosHijo,index)=>{
                            return <tr className="border" id={datosHijo + index}>
                                    <td style={{paddingLeft:'20px', paddingRight:'10px', width:'50px'}}>
                                        <input id={datosHijo.id} 
                                               type="checkbox" 
                                               onChange={()=>onChangeCheck(datosHijo.id)}
                                               value={registroChecked[index].estado}
                                               />
                                    </td>
                                    <td > {datosHijo.descripcion} </td>
                                   
                                </tr>
                        })}</Table>
                    </div>
        })
        setListadoArbol(datosArbol)

        if(valorFiltro===null){
            const cargarCombos= async () =>{
                let microServicio = null
                let loginUser = JSON.parse(window.localStorage.getItem('logearUsuario'))
                let menu = loginUser.perfilUsuario.menu
                for(let obj of menu){
                    if(obj.pantalla === 'Carga Masiva'){
                        microServicio = obj.microServicio
                        break
                    }
                }

                // const obj = {configuracion:{ microServicio: microServicio}}
               // let apiActividades = JSON.parse(window.localStorage.getItem('actividades'))                
            }
            cargarCombos()
        }

    },[valorFiltro])

    return <div>                
                <div className="col-auto mt-2">
                    <div className="input-group mb-2">
                        <span className="input-group-text">
                            <FaSearch/>
                        </span>
                        <input type="text" 
                               className="form-control" 
                               id="inlineFormInputGroup" 
                               placeholder="Filtrar el árbol"
                               onChange={onFiltrarArbol}/>
                    </div>
                </div>
                <div className="border" style={{overflow:'scroll',height: '50vh'}}>{listadoArbol}</div>
           </div>
}