import React, { useEffect, useState } from 'react'
import { IconContext } from "react-icons"
import SweetAlert from 'react-bootstrap-sweetalert'
import {  FcOldTimeCamera } from "react-icons/fc"
import { colores } from '../../componentes/gestionfotos/constantes'
import noImg from '../../imagenes/imagen-no-disponible.png'
import { ModalCamera } from '../../componentes/gestionfotos/modalcamera'
import { GetReporteConsumoCombustible, GuardarConsumoCombustible , ConceptoAprobacionConsumoCombustible} from  '../../componentes/api/consumirapi'
import { resizeBase64Img } from '../../componentes/gestionfotos/cambiarImagen'
import Cargando from '../../componentes/cargando/cargar'




const initState = {
    respuestaGet: {
        tituloPantalla: 'Reporte Consumo Combustible',
        id: null,
        idEquipo:'',
        idEstacion:'',        
        fechaTanqueo:new Date(new Date()),
        tipoCombustible:'',
        valorUnitGalon :0,
        cantKilUltimo :0,
        cantGalTanqueo :0,
        canKilTanqueo :0,
        numFactura :'',
        cargoa : '',        
        observacion :'',
        estado :'',
        observacionSupervisor : '',
        fotoTacometro:null,
        fotoFactura:null,
        cboEquipo:[],
        cboEstacion:[],
        listadoObjetosEstado: [{
            activo: false,
            id: '',
            nombre: '',
            visible: true
        }]
    },
    form: {
        LblEquipo: { id: '', nombre: '', activo: false, visible: '', value: '' },        
        TxtFecha: { id: '', nombre: '', activo: false, visible: '', value: '' },
        TxtValor: { id: '', nombre: '', activo: false, visible: '', value: '' },
        TxtCantidad: { id: '', nombre: '', activo: false, visible: '', value: '' },
        TxtCanKilTanqueo: { id: '', nombre: '', activo: false, visible: '', value: '' },
        TxtCantKilUltimo: { id: '', nombre: '', activo: false, visible: '', value: '' },
        TxtObservacion: { id: '', nombre: '', activo: false, visible: '', value: '' },
        TxtObsConcepto: { id: '', nombre: '', activo: false, visible: '', value: '' },
        fotoTacometro: { id: '', nombre: '', activo: false, visible: '', value: '' },
        fotoFactura: { id: '', nombre: '', activo: false, visible: '', value: '' },
        TxtCargoa: { id: '', nombre: '', activo: false, visible: '', value: '' },
        cboEquipo : [{id:'00000000-0000-0000-0000-000000000000',descripcion:'Seleccione',idTipoCombustible:'', tipoEquipo:'',ultimoKmTanqueo:0}],
        cboEstacion : [{id:'00000000-0000-0000-0000-000000000000',nombre:'Seleccione',valorUnitGalon:0,valorUnitGalon2:0,valorUnitGalon3:0}],        
        lblTipoCombustible : 'Combustible:'
    },
    openCamera: false,
}

export const ConsumoCombustible = (props) => {

	const [cargando, setCargando] = useState(false)
    const [stateConsumoCombustible, setstateConsumoCombustible] = useState(initState)	
	const [consumiendoApi, setconsumiendoApi] = useState(false)
    const [idRegistro, setidRegistro] = useState(props.idRegistro)         
    const [idEquipo, setidEquipo] = useState(null)
    const [idEstacion, setidEstacion] = useState(null)
    const { form } = stateConsumoCombustible;
    const { TxtFecha, TxtValor, TxtCantidad, TxtObservacion, TxtObsConcepto, cboEquipo,  cboEstacion, TxtCanKilTanqueo , TxtCantKilUltimo, TxtCargoa} = form;    	
    const [openCamera, setOpenCamera] = useState(false);
    const [fotoCambio, setFotoCambio] = useState(null);
    const [componenteCargado ,setcomponenteCargado] = useState(false);
     
    
    const onEquipo = (e) => {
        setidEquipo(e.target.value)
        const index = cboEquipo.findIndex(equipo => equipo.id === e.target.value )
        if (index>=0 && TxtCargoa.value===''){           
            setstateConsumoCombustible({...stateConsumoCombustible, form: {...form, "TxtCargoa": {...form[TxtCargoa], value: cboEquipo[index].tipoEquipo } } });
            setstateConsumoCombustible({...stateConsumoCombustible, form: {...form, "TxtCantKilUltimo": {...form[TxtCantKilUltimo], value: cboEquipo[index].ultimoKmTanqueo } } });
        }
    }

    const onEstacion = (e) => {
        const valUnitGalon=form.cboEstacion.find(item=> item.id=== e.target.value).valorUnitGalon
        setidEstacion(e.target.value)
        if (valUnitGalon>=0){
            setstateConsumoCombustible({...stateConsumoCombustible, form: {...form, "TxtValor": {...form[TxtValor], value: valUnitGalon } } });
        }
    }
    const cerrar = () => {        
        if (componenteCargado){
            props.setactualizarDatos(props.actualizarDatos===false ? true : false) 
            props.setshowConsumoCombustible(false)
        }
    }
    const handleForm = (target) => {
        const { name, value } = target;
        const { form } = stateConsumoCombustible;
       
        setstateConsumoCombustible({...stateConsumoCombustible, form: {...form, [name]: {...form[name], value: value } } });
    }
   
    const btnGuardar = async(btnSlected) => {
        console.log(btnSlected)  
        setCargando(true)    
        try {		
            if (idEstacion !=='00000000-0000-0000-0000-000000000000' && idEquipo!=='00000000-0000-0000-0000-000000000000' && Number(form.TxtCantidad.value)>0 && Number(form.TxtCanKilTanqueo.value)>0 ){                         
                const archivoTacometro = stateConsumoCombustible.form.fotoTacometro;
                const archivoFactura = stateConsumoCombustible.form.fotoFactura;
                let str64Factura=''
                let str64Tacometro=''
                let extensionFoto=''
                let extensionFinal=''
                let nuevoType=''
                if (archivoTacometro !== undefined &&  archivoTacometro!==null && archivoTacometro.value!==null && archivoTacometro.value.indexOf('http') === -1) {                    
                    extensionFoto=archivoTacometro.value.substring(0,20).replace('data:image/','')
                    extensionFoto=extensionFoto.substring(0,extensionFoto.indexOf(';'))
                    extensionFinal=extensionFoto
                    nuevoType = String.prototype.concat('data:image/',extensionFoto,';base64,')
                    var image = new Image();
                    image.src = archivoTacometro.value;

                    const vardataCompre = (archivoTacometro.value.length > 12000000 ? await resizeBase64Img(archivoTacometro.value, image.height, image.width, 0.3) :
                                        archivoTacometro.value.length > 9000000 ? await resizeBase64Img(archivoTacometro.value, image.height, image.width, 0.4) :
                                        archivoTacometro.value.length > 6000000 ? await resizeBase64Img(archivoTacometro.value, image.height, image.width, 0.45) :
                                        archivoTacometro.value.length > 4000000 ? await resizeBase64Img(archivoTacometro.value, image.height, image.width, 0.50) :
                                        archivoTacometro.value.length > 3000000 ? await resizeBase64Img(archivoTacometro.value, image.height, image.width, 0.6) :
                                        archivoTacometro.value.length > 2000000 ? await resizeBase64Img(archivoTacometro.value, image.height, image.width, 0.7) :
                                        archivoTacometro.value.length > 1000000 ? await resizeBase64Img(archivoTacometro.value, image.height, image.width, 0.8) :
                                        archivoTacometro.value)
                    str64Tacometro=vardataCompre.replace(nuevoType, '');
                    		
                }
                if (archivoFactura !== undefined &&  archivoFactura!==null && archivoFactura.value!==null && archivoFactura.value.indexOf('http') === -1) {
                    extensionFoto=archivoFactura.value.substring(0,20).replace('data:image/','')
                    extensionFoto=extensionFoto.substring(0,extensionFoto.indexOf(';'))
                    extensionFinal+=';' + extensionFoto
                    nuevoType = String.prototype.concat('data:image/',extensionFoto,';base64,')
                    var imageDFactura = new Image();
                    imageDFactura.src = archivoFactura.value;

                    const vardataCompreFactura2 = archivoFactura.value.length > 12000000 ? await resizeBase64Img(archivoFactura.value, imageDFactura.height, imageDFactura.width, 0.3) :
                    archivoFactura.value.length > 9000000 ? await resizeBase64Img(archivoFactura.value, imageDFactura.height, imageDFactura.width, 0.4) :
                    archivoFactura.value.length > 6000000 ? await resizeBase64Img(archivoFactura.value, imageDFactura.height, imageDFactura.width, 0.45) :
                    archivoFactura.value.length > 4000000 ? await resizeBase64Img(archivoFactura.value, imageDFactura.height, imageDFactura.width, 0.50) :
                    archivoFactura.value.length > 3000000 ? await resizeBase64Img(archivoFactura.value, imageDFactura.height, imageDFactura.width, 0.6) :
                    archivoFactura.value.length > 2000000 ? await resizeBase64Img(archivoFactura.value, imageDFactura.height, imageDFactura.width, 0.7) :
                                        archivoFactura.value.length > 1000000 ? await resizeBase64Img(archivoFactura.value, imageDFactura.height, imageDFactura.width, 0.8) :
                                        archivoFactura.value;
                    str64Factura= vardataCompreFactura2.replace(nuevoType, '')
                }
                
                if ( (str64Factura==='' && (archivoFactura.value===null || archivoFactura.value.indexOf('http') === -1 )) &&
                     (str64Tacometro==='' && (archivoTacometro.value===null || archivoTacometro.value.indexOf('http') === -1))){
                    console.log('Es necesario asociar una imagen');
                    // throw new Error('Es necesario asociar una imagen');
                    window.alert('Es necesario asociar una imagen');       
                } 
                else {  
                    const index = cboEquipo.findIndex(equipo => equipo.id === idEquipo )                    
                    const response = await GuardarConsumoCombustible({
                        id: idRegistro,
                        idEquipo: idEquipo,
                        idEstacion: idEstacion,
                        fechaTanqueo: stateConsumoCombustible.form.TxtFecha.value.toLocaleString('es-CO'),
                        valorUnitGalon: parseFloat(stateConsumoCombustible.form.TxtValor.value),
                        cantKilUltimo: parseFloat(stateConsumoCombustible.form.TxtCantKilUltimo.value),
                        cantGalTanqueo: parseFloat(stateConsumoCombustible.form.TxtCantidad.value),
                        canKilTanqueo : parseFloat(stateConsumoCombustible.form.TxtCanKilTanqueo.value),
                        numFactura: '',
                        cargoa: index>=0 && TxtCargoa.value==='' ? cboEquipo[index].tipoEquipo : TxtCargoa.value,
                        observacion: stateConsumoCombustible.form.TxtObservacion.value,
                        estado: null,
                        observacionSupervisor:null,
                        filename: extensionFinal,
                        fotoTacometro: str64Tacometro,
                        fotoFactura : str64Factura
                    },
                    props.onCambiarOffline, props.setCrudEjecutadoBien,props.urlMicroservicio);                                                            
                    if (response !== undefined && response.hasOwnProperty('linea')) {
                        window.alert(response.mensaje);
                        console.log(response);    
                    }         
                    else{                                 
                        setidRegistro(response)
                        setcomponenteCargado(false)                                      
                    }                          
                }
                		
            } else {
                // console.log('Es necesario asociar una imagen');
                // throw new Error('Es necesario asociar una imagen');
                window.alert('Es necesario especificar Equipo, Fecha, Estación, Galones y/o KM.Tacómetro');                
            }
        } catch (error) {			
            console.log(error)
            window.alert(error.mensaje);            
        }        
      setCargando(false);
    }
		
    
    const btnConcepto = async(btnSlected) => {
        console.log(btnSlected)  
        setCargando(true)
        if (btnSlected==='Anular' && TxtObsConcepto.value===null)  {
            window.alert('Es necesario especificar la razón por la cual anula el reporte');                
        }
        else{        
            try {
                const index = cboEquipo.findIndex(equipo => equipo.id === idEquipo )                       
                let response = await ConceptoAprobacionConsumoCombustible({
                    id: idRegistro,
                    estado: btnSlected==='Aprobar' ? 'Aprobado' : 'Anulado',                
                    contrato: btnSlected==='Anular' ? 'ANULADO:'+ TxtCargoa.value : index>=0 && TxtCargoa.value==='' ? cboEquipo[index].tipoEquipo : TxtCargoa.value,                
                    observacionSupervisor: stateConsumoCombustible.form.TxtObsConcepto.value,                
                    },props.onCambiarOffline, props.setCrudEjecutadoBien,props.urlMicroservicio);
                if (response !== undefined && response.hasOwnProperty('linea')) {
                    window.alert(response.mensaje);
                    console.log(response);    
                }         
                else{                
                    setcomponenteCargado(false)
                }    
            } catch (error) {
                console.log(error)
                window.alert(error.mensaje); 
            }
        }
        setCargando(false);
    }

    const camera = (fotoCambio) => {
        setOpenCamera(true)  
        setFotoCambio(fotoCambio)
        
    }

    // Relaliza la petición al back y pasa a renderizar labels y valores de la vista
    const consultaEjecucion = async(idreporte,microServicio) => {        
        try 
        {
            const response= await GetReporteConsumoCombustible({idreporte, microServicio},props.onCambiarOffline);
            if(response!==null&&response!==false){
                setidEquipo(response.idEquipo)   
                setidEstacion(response.idEstacion)           
            }
            
            if (response!==false){
                renderizarLabelsValores(response, idreporte);
                setconsumiendoApi(false); 
                setcomponenteCargado(true);                
            }
                        
        } catch (error) {
            console.log(error)            
            props.setshowConsumoCombustible(false);
        }
        // setstateConsumoCombustible({...stateConsumoCombustible, respuestaGet: response});
    }

    // obtiene el valor de los labels para cada elemento del dom
    const getLabel = (id) => {
        const { respuestaGet } = stateConsumoCombustible;
        const { listadoObjetosEstado  } = respuestaGet;    
        if (listadoObjetosEstado.length>1){
            const dataById = listadoObjetosEstado.filter(e => e.id.toLowerCase() === id.toLowerCase());

            if (id==='LblTipoCombustible'){
               return String.prototype.concat(
                    dataById[0].nombre ? dataById[0].nombre : '',
                    idEquipo ?
                    form.cboEquipo.find(item=> item.id=== idEquipo)?.idTipoCombustible :''
                    )
            }
            else{
                return dataById[0].nombre ? dataById[0].nombre : '';   
            }
        }
        return '';             
    }

    // Renderiza labels y valores de la vista
    const renderizarLabelsValores = (response) => {
        // renderizar valores form
        const { listadoObjetosEstado, cboEquipo, cboEstacion } = response;
        
        const propForm = Object.keys(form);
        const valuesForm = {};
        let fotoTacometro= noImg;
        let fotoFactura= noImg;
        propForm.forEach(prop => {
            const tempDato = listadoObjetosEstado.filter(e => e.id === prop)[0];
            if (tempDato) {
                if (tempDato.nombre.includes('Datatable')) {
                    const nombre = tempDato.nombre.split('.')[1];
                    const nombreFromDataTable = response[nombre];
                    tempDato.value = nombreFromDataTable;
                    if (tempDato.id==='fotoTacometro'){
                        fotoTacometro=nombreFromDataTable;
                    }
                    else if (tempDato.id==='fotoFactura'){
                        fotoFactura=nombreFromDataTable;
                    }
                } else {
                    tempDato.value = tempDato.nombre ? tempDato.nombre : '';
                }
            }
            valuesForm[prop] = tempDato;

        });
        cboEquipo.push({id:'00000000-0000-0000-0000-000000000000',descripcion:'Seleccione',idTipoCombustible:'',tipoEquipo:'',ultimoKmTanqueo:0})
        cboEstacion.push({id:'00000000-0000-0000-0000-000000000000',nombre:'Seleccione',valorUnitGalon:0,valorUnitGalon2:0,valorUnitGalon3:0})
        valuesForm.cboEquipo=cboEquipo 
        valuesForm.cboEstacion=cboEstacion 
        handleForm({ name: 'fotoTacometro', value: fotoTacometro })
        handleForm({ name: 'fotoFactura', value: fotoFactura })
        setstateConsumoCombustible({...stateConsumoCombustible, respuestaGet: response, form: valuesForm })

    }

    const getVisibleActivoElement = (id, propiedad) => {
        if (componenteCargado){
            const { respuestaGet } = stateConsumoCombustible;
            const { listadoObjetosEstado } = respuestaGet;
            const dataById = listadoObjetosEstado.filter(e => e.id === id);
    
            if (dataById.length > 0) {
                return dataById[0][propiedad];
            } else {
                return true
            }
        }
        return false
        
    }

    /*
    const searchPhoto = (target) => {
        let img = target.files[0];

        const reader = new FileReader();
        reader.onloadend = () => {
            handleForm({ name: 'foto', value: reader.result })
        };
        reader.readAsDataURL(img);
    }
	*/
    useEffect(() => {
        if (props.showConsumoCombustible && !componenteCargado && !consumiendoApi) {   
            setCargando(true);
            if (idRegistro === undefined || idRegistro === null){ 
                setconsumiendoApi(true);            
                consultaEjecucion(null, props.urlMicroservicio);                         
            }
            else{  
                setconsumiendoApi(true);           
                consultaEjecucion(idRegistro, props.urlMicroservicio);                                                       
            }            
            setCargando(false);
        }

        return () => {
           
        }
    }, [componenteCargado])

    if (componenteCargado===true && !cargando){
        return (
            <SweetAlert
                show={props.showEjecutar}                
                // onConfirm={async()=>SincronizaReportesEtapaTarea()}
                onConfirm={()=>null}
                showCancel={false}
                showConfirm={false}
                confirmBtnText={"Aprobar"}
                closeOnClickOutside={false}
                showCloseButton={true}>
                <div>
                    <div style={{...estilos.fila, borderBottom:'1px #0000002b solid', marginBottom:'1px', paddingBottom:'1px'}}>
                       {!cargando &&<button onClick={()=>{
                            if(getVisibleActivoElement("CmdGuardar", "activo"))  btnGuardar('Guardar')
                            }}  style={{
                                ...estilos.botonEjeAct,
                                 color:getVisibleActivoElement("CmdGuardar", "activo") ? 'white': 'gray',
                                 display: getVisibleActivoElement("CmdGuardar", "visible") ? 'block' : 'none',
                                 cursor: getVisibleActivoElement("CmdGuardar", "activo") ? 'pointer' :'default'}}>
                                     {getLabel("CmdGuardar")}
                        </button>}
                        {
                            getVisibleActivoElement("LblTituloPantalla", "visible") && 
                            <h5 style={{width:'100%', textAlign:'center', color: getVisibleActivoElement("LblTituloPantalla", "activo") ? 'black': 'gray'}} id="LblTituloPantalla">{getLabel("LblTituloPantalla")}</h5>
                        }
                        {
                            getVisibleActivoElement("CmdCerrar", "visible") &&
                            <button onClick={()=>{
                                    if(getVisibleActivoElement("CmdCerrar", "activo")) cerrar();
                                }}
                                type="button" className="btn btn-outline-danger btn-sm" id="CmdCerrar" style={{color:getVisibleActivoElement("CmdCerrar", "activo") ? 'black': 'gray'}}>{getLabel("CmdCerrar")}</button>
                        }
                    </div>
                    
                    <div style={{...estilos.fila, borderBottom:'2px #0000002b solid', marginBottom:'5px', paddingBottom:'5px'}}>
                        <p style={{paddingRight:'20px' }}>Equipo</p>
                        <select value={idEquipo} onChange={onEquipo} id="idEquipo"  className="form-select form-select-sm">
                            {cboEquipo.map((obj)=>{
                                return <option value={obj.id}>{obj.descripcion}</option>
                            })}
                        </select>
                        { <p id="LblTipoCombustible" style={{fontSize:'small', color: 'black'}}>
                            {getLabel("LblTipoCombustible")}</p>
                        }
                        
                    </div>
    
                    <div style={{...estilos.fila, borderBottom:'2px #0000002b solid', marginBottom:'5px', paddingBottom:'5px'}}>
                        { getVisibleActivoElement("LblFecha", "visible") && <p style={{fontWeight:'bold', color:getVisibleActivoElement("LblFecha", "activo") ? 'black': 'gray'}}>
                            {getLabel("LblFecha")}</p> 

                        }
                        { getVisibleActivoElement("TxtFecha", "visible") &&
                                <input onChange={({target})=>handleForm(target)} disabled={!getVisibleActivoElement("TxtFecha", "activo")} name="TxtFecha" type="datetime-local" className="form-control" aria-label="TxtFecha" max={new Date()}
                                aria-describedby="basic-addon1" value={TxtFecha.value}/>
                        }  
                        <p>Estación:</p>
                         <select value={idEstacion} onChange={onEstacion} id="idEstacion"  className="form-select form-select-sm">
                            {cboEstacion.map((obj)=>{
                                return <option value={obj.id}>{obj.nombre}</option>
                            })}
                        </select>
                    </div>
                    
                    <div style={{...estilos.fila, borderBottom:'2px #0000002b solid', marginBottom:'5px', paddingBottom:'5px'}}>
                        <div style={{display:'flex', justifyContent:'space-around', marginBottom:'15px'}}>
                                <IconContext.Provider value={{ color: "gray", className: "global-class-name", size:'2em' }}>
                                    <div style={{display:'flex', flexDirection:'column', }}>
                                        <FcOldTimeCamera onClick={()=>{
                                            if(getVisibleActivoElement("CmdFactura", "activo")) camera('Factura');
                                            }} className="cursor" title='Capturar foto factura' style={{
                                                ...estilos.estiloIcono,
                                                cursor: getVisibleActivoElement("CmdFactura", "activo") ? 'pointer' :'default',
                                                display: getVisibleActivoElement("CmdFactura", "visible") ? 'block' : 'none'}} />
                                            
                                        
                                    </div>
                                </IconContext.Provider>
                                <img src={form.fotoFactura &&  form.fotoFactura.value!==null &&  form.fotoFactura.value!=='' ? form.fotoFactura.value : noImg} alt="" style={{width:'300px',height:'195px', borderRadius:'20px'}}/>
                        </div>
                        <div className="form-control" style={{width:'200px' }}>
                            <p id="LblValorunitario" style={{color:getVisibleActivoElement("LblCantidad", "activo") ? 'black': 'gray'}}>V.Unitario</p>                                 
                            <input id="TxtValor" style={{marginRight:'5px', textAlign:'right',width:'100%', display: getVisibleActivoElement("TxtValor", "visible") ? 'block' : 'none'}} 
                                onChange={({target})=>handleForm(target)} name="TxtValor" type="number" className="form-control" min="0" max="30000"
                                disabled={!getVisibleActivoElement("TxtValor", "activo")} placeholder="Ingrese valor unitario del galón" aria-label="TxtValor" aria-describedby="basic-addon1" 
                                value={TxtValor.value}/>
                        
                            { getVisibleActivoElement("LblCantidad", "visible") && <p id="LblCantidad" style={{color:getVisibleActivoElement("LblCantidad", "activo") ? 'black': 'gray'}}>
                                {getLabel("LblCantidad")}</p> 
                            }
                            <input id="TxtCantidad" style={{marginRight:'5px', width:'100%', textAlign:'right',display: getVisibleActivoElement("TxtCantidad", "visible") ? 'block' : 'none'}} 
                                onChange={({target})=>handleForm(target)} name="TxtCantidad" type="number" className="form-control"  min="1" max="99"
                                disabled={!getVisibleActivoElement("TxtCantidad", "activo")} placeholder="Ingresa una cantidad" aria-label="TxtCantidad" aria-describedby="basic-addon1" 
                                value={TxtCantidad.value}/>

                            { getVisibleActivoElement("lblValorTanqueo", "visible") && <p id="lblValorTanqueo" style={{color:getVisibleActivoElement("lblValorTanqueo", "activo") ? 'black': 'gray'}}>
                                {String.prototype.concat(getLabel("lblValorTanqueo") ,' $' ,(Number(TxtCantidad.value) * Number(TxtValor.value)).toLocaleString('en-eu'))}</p> 
                            }
                        </div>
                    </div>
                    
                    <div style={{...estilos.fila, borderBottom:'2px #0000002b solid', marginBottom:'5px', paddingBottom:'5px'}}>
                        <div style={{display:'flex', justifyContent:'space-around', marginBottom:'15px'}}>
                                <IconContext.Provider value={{ color: "gray", className: "global-class-name", size:'2em' }}>
                                    <div style={{display:'flex', flexDirection:'column', }}>
                                        <FcOldTimeCamera onClick={()=>{
                                            if(getVisibleActivoElement("CmdTacometro", "activo")) camera('Tacómetro');
                                            }} className="cursor" title='Capturar foto tacómetro' style={{
                                                ...estilos.estiloIcono,
                                                cursor: getVisibleActivoElement("CmdTacometro", "activo") ? 'pointer' :'default',
                                                display: getVisibleActivoElement("CmdTacometro", "visible") ? 'block' : 'none'}} 
                                        />                                            
                                    </div>
                                </IconContext.Provider>                                
                                <img src={form.fotoTacometro  &&  form.fotoTacometro.value!==null &&  form.fotoTacometro.value!=='' ? form.fotoTacometro.value : noImg} alt="" style={{width:'300px', height:'195px',borderRadius:'20px'}}/>                                                                                            
                        </div>
                        <div className="form-control" style={{width:'200px' }}>
                            <p id="LblCanKilTanqueo" style={{color:getVisibleActivoElement("LblCanKilTanqueo", "activo") ? 'black': 'gray'}}>KM.Tacómetro</p>                                 
                            <input id="TxtCanKilTanqueo" style={{marginRight:'5px', textAlign:'right',width:'80%', display: getVisibleActivoElement("TxtCanKilTanqueo", "visible") ? 'block' : 'none'}} 
                                onChange={({target})=>handleForm(target)} name="TxtCanKilTanqueo" type="number" className="form-control"
                                disabled={!getVisibleActivoElement("TxtCanKilTanqueo", "activo")} placeholder="Ingrese kilometraje actual" aria-label="TxtCanKilTanqueo" aria-describedby="basic-addon1" 
                                value={TxtCanKilTanqueo.value}/>
                        
                            { getVisibleActivoElement("lblCantKilUltimo", "visible") && <p id="lblCantKilUltimo" style={{color:getVisibleActivoElement("lblCantKilUltimo", "activo") ? 'black': 'gray'}}>
                                {getLabel("lblCantKilUltimo")}</p> 
                            }
                            <input id="TxtCantKilUltimo" style={{marginRight:'5px', width:'80%', textAlign:'right',display: getVisibleActivoElement("TxtCantKilUltimo", "visible") ? 'block' : 'none'}} 
                                onChange={({target})=>handleForm(target)} name="TxtCantKilUltimo" type="number" className="form-control"
                                disabled={!getVisibleActivoElement("TxtCantKilUltimo", "activo")} placeholder="Ingresa una cantidad" aria-label="TxtCantKilUltimo" aria-describedby="basic-addon1" 
                                value={TxtCantKilUltimo.value}/>

                            { getVisibleActivoElement("lblKMTotal", "visible") && <p id="lblKMTotal" style={{color:getVisibleActivoElement("lblKMTotal", "activo") ? 'black': 'gray'}}>
                                { String.prototype.concat( getLabel("lblKMTotal"),Number(TxtCanKilTanqueo.value) - Number(TxtCantKilUltimo.value))}</p> 
                            }
                        </div>
                    </div>
                   
                    <div style={{marginBottom:'10px', textAlign:'center', width:'100%'}}>
                        <textarea onChange={({target})=>handleForm(target)} name="TxtObservacion" className="form-control" value={TxtObservacion.value ? TxtObservacion.value : ''}
                        disabled={!getVisibleActivoElement("TxtObservacion", "activo")} aria-label="With textarea" placeholder="Observaciones" 
                            style={{height: "40px", display: getVisibleActivoElement("TxtObservacion", "visible") ? 'block' : 'none'}} />
                            
                        <textarea onChange={({target})=>handleForm(target)} name="TxtObsConcepto" className="form-control"
                        disabled={!getVisibleActivoElement("TxtObsConcepto", "activo")} aria-label="With textarea" placeholder="Observaciones en la confirmación o anulación" 
                        style={{height: "40px", marginTop:'10px', display: getVisibleActivoElement("TxtObsConcepto", "visible") ? 'block' : 'none'}} value={TxtObsConcepto.value ? TxtObsConcepto.value : ''}/>
                    </div>
                    <div id="btnsGroup2" style={{display:'flex', justifyContent:'space-around', marginBottom:'10px', textAlign:'center', width:'100%'}}>                                                                   
                        {!cargando && <button onClick={()=>{
                            if(getVisibleActivoElement("CmdAprobar", "activo"))  btnConcepto('Aprobar')
                            }}   style={{
                                ...estilos.botonEjeAct,
                                 color:getVisibleActivoElement("CmdAprobar", "activo") ? 'white': 'gray',
                                 display: getVisibleActivoElement("CmdAprobar", "visible") ? 'block' : 'none',
                                 cursor: getVisibleActivoElement("CmdAprobar", "activo") ? 'pointer' :'default'}}>
                                     {getLabel("CmdAprobar")}
                        </button>}
                        { getVisibleActivoElement("LblCargoa", "visible") && <p id="LblCargoa" style={{color:getVisibleActivoElement("LblCargoa", "activo") ? 'black': 'gray'}}>
                                {getLabel("LblCargoa")}</p> 
                            }
                            <input id="TxtCargoa" style={{marginRight:'5px', width:'100%', textAlign:'right',display: getVisibleActivoElement("TxtCargoa", "visible") ? 'block' : 'none'}} 
                                onChange={({target})=>handleForm(target)} name="TxtCargoa" type="text" className="form-control"
                                disabled={!getVisibleActivoElement("TxtCargoa", "activo")} placeholder="Ingresa Contrato" aria-label="TxtCargoa" aria-describedby="basic-addon1" 
                                value={TxtCargoa.value}/>
                        {!cargando && <button onClick={()=>{
                            if(getVisibleActivoElement("CmdAnular", "activo"))  btnConcepto('Anular')
                            }}  style={{
                                ...estilos.botonEjeAct,
                                 color:getVisibleActivoElement("CmdAnular", "activo") ? 'white': 'gray',
                                 display: getVisibleActivoElement("CmdAnular", "visible") ? 'block' : 'none',
                                 cursor: getVisibleActivoElement("CmdAnular", "activo") ? 'pointer' :'default'}}>
                                     {getLabel("CmdAnular")}
                        </button>}
                    </div>
    
                    <ModalCamera openModal={openCamera} setOpenModal={setOpenCamera} btnGuardar={btnGuardar} handleForm={handleForm}  fotoCambio= {fotoCambio} />
                                    
                </div>
            </SweetAlert>
        )
    }
    else if (cargando || consumiendoApi){
        return <Cargando/>
    }
    
}

const estilos = {
    fila:{display:'flex', justifyContent:'space-between', alignItems:'center', marginBottom:'5px', marginTop:'5px'},
    botonEjeAct:{
        borderRadius: '10px',
        padding:'10px 5px',
        backgroundColor: colores.primario,
        color:'white',
        fontWeight:'bold',
        fontSize:'15px',
        marginLeft:'2px',
        border:'blue'
    },
    estiloIcono: {marginLeft:'0px', marginRight:'0px',}
}