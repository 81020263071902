import {publicIpv4} from 'public-ip'

export const NombreDispositivo = navigator.userAgent
export const TipoClienteAccede = "Capsule Combustible Demo;rev:0.1.0"
export const urlBase = 'https://capsule.oil.services.cmsolinfo.com';

export async function ipPublica(){
    let IPPublicaDispositivo = ""
    const getClientIp = async () => await publicIpv4({ fallbackUrls: ["https://ifconfig.co/ip"] });
        await getClientIp()
            .then((dato) => { IPPublicaDispositivo = dato })
            .catch((error) => {
                return {
                    ok: false,
                    user: error,
                    mensaje: "No se ha podido realizar la conexión!!"
                } 
            })
    return IPPublicaDispositivo;
}